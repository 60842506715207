<template>
    <div>
        <!--年费代缴-->
        <div v-if="nowIndex == 0">
            <div class="right_header">
                <div class="dp-flex query-form">
                    <div class="flex-align-center">
                        <span>订单号：</span>
                        <Input v-model="payParams.orderSn" style="width:200px"/>
                    </div>
                    <div class="flex-align-center">
                        <span>订单状态：</span>
                        <Select v-model="payParams.type" :transfer="transfer" style="width:100px">
                            <Option value="0">全部</Option>
                            <Option value="1">待支付</Option>
                            <Option value="2">代缴中</Option>
                            <Option value="3">已完成</Option>
                        </Select>
                    </div>
                    <div class="flex-align-center" id="renderSelect">
                        <span>支付方式：</span>
                        <Select v-model="payParams.payMethod" :transfer="transfer" style="width:80px">
                            <Option value="">全部</Option>
                            <Option value="0">支付宝</Option>
                            <Option value="1">微信</Option>
                            <Option value="2">线下</Option>
                        </Select>
                    </div>
                    <div class="flex-align-center">
                        <div class="button_style" @click="search()">
                            查询
                        </div>
                        <div @click="exportDemand" style="background:#1DA3E8" class="button_style">
                            导出
                        </div>
                        <div class="button_style" style="background:#7E73E7" @click="getPatentList(); nowIndex = 3;">
                            新增年费代缴
                        </div>
                    </div>
                </div>
            </div>
            <div class="right_real_content" id="djnf">
                <div v-if="dataList.length == 0" style="text-align: center">
                    暂无数据
                </div>
                <div v-else v-for="(item, index) in dataList"
                     style="height: auto;border: 1px solid #EEF1F6;margin-bottom: 20px;" :key="index">
                    <div
                            id="djnf2"
                            style="
              height: 60px;
              background-color: #EEF1F6;
              line-height: 60px;
              font-size: 14px;
            "
                    >
                        <Row>
                            <Col span="4">
                <span
                        class="order_span"
                        :title="item.orderSn"
                        style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: left;
                  "
                >订单号：{{ item.orderSn }}
                </span>
                            </Col>
                            <Col span="3">
                                <span class="order_span">时间：{{ item.createTime }}</span>
                            </Col>
                            <Col span="3">
                                <span class="order_span">金额：{{ item.totalAmount }}元</span>
                            </Col>
                            <Col span="3">
                <span class="order_span"
                >服务费：{{ item.serviceAmount }}元</span
                >
                            </Col>
                            <Col span="3">
                <span class="order_span" v-if="item.payMethod == 0"
                >支付方式：支付宝</span
                >
                                <span class="order_span" v-if="item.payMethod == 1"
                                >支付方式：微信</span
                                >
                                <span class="order_span" v-if="item.payMethod == 2"
                                >支付方式：线下</span
                                >
                            </Col>
                            <Col span="4">
                <span class="order_span"
                >订单状态：<span style="color: #fda736">{{
                    item.statusName
                  }}</span></span
                >
                            </Col>
                            <Col span="4">
                <span
                        class="order_span"
                        style="display: inline-block; float: right; padding: 0"
                        v-if="item.status == 0"
                >
                  <span
                          style="color: #1767E0; cursor: pointer"
                          @click="goPay(item)"
                  >去支付</span
                  >
                  &nbsp;|&nbsp;
                  <span
                          style="color: #1767E0; cursor: pointer"
                          @click="removeOrder(item)"
                  >取消订单</span
                  >
                </span>
                                <span
                                        class="order_span"
                                        style="display: inline-block; float: right; padding: 0"
                                        v-if="item.status == 10"
                                >
                  <span
                          style="color: #1767E0; cursor: pointer"
                          @click="delOrder(item)"
                  >删除订单</span
                  >
                </span>
                                <span
                                        class="order_span"
                                        style="display: inline-block; float: right; padding: 0"
                                >
                </span>
                            </Col>
                        </Row>
                    </div>
                    <div
                            style="height: 70px; font-size: 14px"
                            v-for="(detailItem, detailIndex) in item.detail"
                            :key="detailIndex"
                    >
                        <Row>
                            <Col
                                    span="5"
                                    style="text-align: center"
                                    :title="detailItem.orderSn"
                            ><span
                                    :title="detailItem.orderSn"
                                    style="

                    padding: 0 10px;
                    display: -webkit-box;
                    width: 100%;
                    overflow: hidden;
                    textOverflow: ellipsis;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                  "
                            >{{ detailItem.orderSn }}</span
                            ></Col
                            >
                            <Col span="3" style="text-align: center; padding: 0 5px"
                            ><span
                                    :title="detailItem.patentTi"
                                    style="
                    padding: 0 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                            >{{ detailItem.patentTi }}</span
                            ></Col
                            >
                            <Col span="3" style="text-align: center">
                <span class="liang_hang_sheng" :title="detailItem.feeType">
                         {{
                    detailItem.feeType
                  }}
                </span>
                            </Col>
                            <Col span="3" style="text-align: center"><span class="liang_hang_sheng"
                                                                           :title="detailItem.shouldFee">
                {{
                  detailItem.shouldFee
                }}
              </span></Col>
                            <Col span="3" style="text-align: center">
                <span class="liang_hang_sheng" :title="detailItem.feeDate">
                    {{ detailItem.feeDate }}
                </span>

                            </Col>
                            <Col span="3" style="text-align: center">
                <span class="liang_hang_sheng" :title="detailItem.feeStatus">
                     {{ detailItem.feeStatus }}
                </span>

                            </Col>
                            <Col span="4" style="text-align: center;cursor: pointer"><span
                                    @click="seeDetail(detailItem)">查看详情</span></Col>
                        </Row>
                    </div>
                </div>
                <Page
                        :total="total"
                        show-elevator
                        style="margin-top: 50px; float: right; margin-bottom: 10px"
                        @on-change="pageChange"
                />
            </div>
        </div>

        <!--新增年费代缴-->
        <div v-if="nowIndex == 3">
            <div class="right_header">
                <div class="dp-flex query-form">
                    <div class="flex-align-center">
                        <span>专利申请号、专利名称或申请人：</span>
                        <Input v-model="patentParams.keywords" style="width:200px"/>
                    </div>
                    <div class="flex-align-center">
                        <div class="button_style" @click="searchAdd()">
                            检索
                        </div>
                        <div class="button_style" style="background:rgb(29, 163, 232)" @click="goJiaoFei()">
                            去缴费
                        </div>
                    </div>
                </div>
            </div>
            <div class="right_real_content">
                <Table
                        border
                        :columns="columns13"
                        :data="data7"
                        :disabled-hover="true"
                        @on-selection-change="selectMu"
                >
                    <template slot-scope="{ row }" slot="name">
                        <strong>{{ row.name }}</strong>
                    </template>
                </Table>
                <Page
                        :total="addtotal"
                        show-elevator
                        style="margin-top: 50px; float: right"
                        @on-change="changePageAdd"
                />
            </div>
        </div>
        <div class="center" style="background-color: white;padding:40px 0" v-if="nowIndex == 5">
            <div style="font-size: 24px;width: 100%;text-align: center;margin-bottom: 45px">
                请您线下支付后，上传支付凭证或联系客服人员审核!
            </div>
            <div style="font-size: 14px;text-align: center;margin-bottom: 15px">
                支付金额：￥{{ priceSum }}
            </div>
            <div style="font-size: 14px;text-align: center;margin-bottom: 30px">
                订单号：{{ lineForm.orderSn }}
            </div>
            <div style="width: 680px;margin:0 auto;background-color:#FAFAFA;height: 250px;padding: 50px 0">
                <div style="height: 30px;line-height: 30px;font-size: 14px;text-align: center">
          <span
                  style="width: 200px;display: inline-block;text-align: right;margin-right: 20px;color: rgb(153, 153, 153);">收款人全称</span>
                    <span style="width: 200px;display: inline-block;text-align: left">南京中高知识产权股份有限公司</span>
                </div>
                <div style="height: 30px;line-height: 30px;font-size: 14px;text-align: center">
          <span
                  style="width: 200px;display: inline-block;text-align: right;margin-right: 20px;color: rgb(153, 153, 153);">收款人账号</span>
                    <span style="width: 200px;display: inline-block;text-align: left">78929389217827316741</span>
                </div>
                <div style="height: 30px;line-height: 30px;font-size: 14px;text-align: center">
          <span
                  style="width: 200px;display: inline-block;text-align: right;margin-right: 20px;color: rgb(153, 153, 153);">开户行名称</span>
                    <span style="width: 200px;display: inline-block;text-align: left">中国人民银行</span>
                </div>
                <div style="height: 30px;line-height: 30px;font-size: 14px;text-align: center">
          <span
                  style="width: 200px;display: inline-block;text-align: right;margin-right: 20px;color: rgb(153, 153, 153);">附加信息及用途</span>
                    <span style="width: 200px;display: inline-block;text-align: left;vertical-align: top">订单号：{{ lineForm.orderSn }}</span>
                </div>
            </div>
            <div
                    style="  border-width: 1px;
          border-color: rgb(255, 106, 0);
          border-style: solid;
          border-width: 1px;
          background-color: rgb(255, 255, 255);
          width: 178px;
          height: 38px;
          color:rgb(255, 106, 0) ;
          cursor: pointer;
          margin: 0 auto;
          text-align: center;
          line-height: 38px;
          margin-top: 30px;" @click="nowIndex = 0;getPay()">查看订单
            </div>
        </div>
        <!--年费代缴结算-->
        <div v-if="nowIndex == 2">
            <div style="height: auto">
                <div
                        style="
            height: 70px;
            background-color: rgba(205, 240, 243, 0.9);
            line-height: 70px;
            font-size: 20px;
            color: black;
            font-weight: bold;
            padding-left: 20px;
          "
                >
                    年费代缴结算页
                </div>
                <div style="height: 210px; padding: 50px 60px" class="div_center">
                    <div
                            style="
              display: flex;
              align-items: center;
              padding: 0 20px;
              height: 95px;
              border: 1px solid #1767E0;
              color: #1767E0;
              background-color: rgba(205, 240, 243, 0.9);
            "
                    >
                        滞纳金缴费截止日期属粗略计算结果，如需代缴，建议先 联系客服
                        确认相关事宜，以免造成代缴失败。本次应缴费用来源于官方数据，官方应缴费用可能会延迟生成，是否准确请以法律规定的依据为准，对费用如有疑问请
                        联系客服 核实。系统平台将会在下一个工作日完成缴费，请提前安排缴费。
                    </div>
                </div>
                <div style="height: auto" class="div_center">
                    <div
                            style="
              height: 100px;
              line-height: 100px;
              font-size: 20px;
              font-weight: bold;
              color: black;
            "
                    >
                        清单
                    </div>
                    <Table
                            border
                            :columns="columnsPatent"
                            :data="patentItem"
                            :disabled-hover="true"
                            style="margin-bottom: 50px"
                    >
                        <template slot-scope="{ row }" slot="name">
                            <strong>{{ row.name }}</strong>
                        </template>
                    </Table>
                </div>
                <div
                        style="height: 180px"
                        :class="{
            div_center: KaiPiao == true,
            div_center_two: KaiPiao == false,
          }"
                >
                    <div
                            style="
              height: 100px;
              line-height: 100px;
              font-size: 20px;
              font-weight: bold;
              color: black;
            "
                    >
                        开票信息
                    </div>

                    <div>
                        是否开票：
                        <RadioGroup v-model="animal">
                            <Radio label="无需发票"></Radio>
                            <Radio label="需要发票"></Radio>
                        </RadioGroup>
                    </div>
                    <div></div>
                </div>

                <!--变化-->
                <div
                        v-show="animal == '需要发票'"
                        style="
            height: 500px;
            padding-top: 35px;
            background-color: rgba(205, 240, 243, 0.4);
            border-style: solid !important;
            border-width: 1px !important;
            border-color: rgb(225, 225, 225) !important;
          "
                        class="div_center"
                >
                    <div id="button_style">
                        <div
                                v-for="(item, index) in faPiaoType"
                                :key="index"
                                @click="faPiaochoose(index)"
                                :class="{
                div_button_select: faPiaoIndex == index,
                div_button: faPiaoIndex != index,
              }"
                        >
                            {{ item }}
                        </div>
                    </div>
                    <div style="width: 50%; margin-top: 50px" v-show="faPiaoIndex == 0">
                        <Form :model="ordinaryInvoiceForm" :label-width="160">
                            <FormItem label="企业名称:">
                                <Input v-model="ordinaryInvoiceForm.qyName"></Input>
                            </FormItem>

                            <FormItem label="发票邮寄地址:">
                                <Input v-model="ordinaryInvoiceForm.address"></Input>
                            </FormItem>

                            <FormItem label="发票收件人姓名:">
                                <Input v-model="ordinaryInvoiceForm.name"></Input>
                            </FormItem>

                            <FormItem label="发票收件人联系方式:">
                                <Input v-model="ordinaryInvoiceForm.phone"></Input>
                            </FormItem>
                        </Form>
                    </div>
                    <div
                            style="margin-top: 50px; padding: 0 40px"
                            v-show="faPiaoIndex == 1"
                    >
                        <Form :model="vatInvoiceForm" :label-width="160">
                            <Row>
                                <Col span="12">
                                    <FormItem label="企业名称:">
                                        <Input
                                                v-model="vatInvoiceForm.qyName"
                                                placeholder="请输入企业名称"
                                        ></Input>
                                    </FormItem>
                                </Col>
                                <Col span="12">
                                    <FormItem label="公司地址:">
                                        <Input
                                                v-model="vatInvoiceForm.address"
                                                placeholder="请输入公司地址"
                                        ></Input>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="12">
                                    <FormItem label="企业税号:">
                                        <Input
                                                v-model="vatInvoiceForm.qysh"
                                                placeholder="请输入企业税号"
                                        ></Input>
                                    </FormItem>
                                </Col>
                                <Col span="12">
                                    <FormItem label="发票邮寄地址:">
                                        <Input
                                                v-model="vatInvoiceForm.fpdz"
                                                placeholder="请输入发票邮寄地址"
                                        ></Input>
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row>
                                <Col span="12">
                                    <FormItem label="企业开户行:">
                                        <Input
                                                v-model="vatInvoiceForm.khh"
                                                placeholder="请输入发票企业开户行"
                                        ></Input>
                                    </FormItem>
                                </Col>
                                <Col span="12">
                                    <FormItem label="发票收件人姓名:">
                                        <Input
                                                v-model="vatInvoiceForm.name"
                                                placeholder="请输入发票收件人姓名"
                                        ></Input>
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row>
                                <Col span="12">
                                    <FormItem label="企业银行开行账号:">
                                        <Input
                                                v-model="vatInvoiceForm.khzh"
                                                placeholder="请输入企业银行开行账号"
                                        ></Input>
                                    </FormItem>
                                </Col>
                                <Col span="12">
                                    <FormItem label="发票收件人联系方式:">
                                        <Input
                                                v-model="vatInvoiceForm.contact"
                                                placeholder="请输入发票收件人联系方式"
                                        ></Input>
                                    </FormItem>
                                </Col>
                            </Row>

                            <Col span="12">
                                <FormItem label="公司电话:">
                                    <Input
                                            v-model="vatInvoiceForm.telephone"
                                            placeholder="请输入公司电话"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Form>
                    </div>
                </div>
                <div style="height: 320px; border-bottom: none" class="div_center">
                    <div
                            style="
              height: 100px;
              line-height: 100px;
              font-size: 20px;
              font-weight: bold;
              color: black;
            "
                    >
                        结算支付
                    </div>
                    <div
                            style="height: 220px; background-color: rgba(205, 240, 243, 0.4)"
                    >
                        <div
                                style="
                height: 160px;
                border-bottom: 2px dotted rgb(218, 226, 229);
                padding: 30px 50px;
              "
                        >
                            <div style="height: 33px; line-height: 20px">
                                代缴专利:{{ patentNum }}件
                            </div>
                            <div style="height: 33px; line-height: 20px">
                                代缴费用（官费）：¥{{ daijiaofee }}
                            </div>
                            <div style="height: 33px; line-height: 20px">
                                服务费：¥{{ servicefee }}
                            </div>
                        </div>
                        <div
                                style="
                height: 60px;
                line-height: 60px;
                float: right;
                margin-right: 50px;
              "
                        >
                            <div>
                                <span>订单总额</span>&nbsp;&nbsp;<span>¥{{ priceSum }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                        style="height: 150px; display: flex; align-items: center"
                        class="div_center"
                >
                    <div style="height: 60px; display: flex; align-items: center">
                        <span style="vertical-align: middle">选择支付方式：</span>
                        <div
                                v-for="(item, index) in paylist"
                                :key="index"
                                @click="payIndex = index"
                                class="nopay_div"
                                :class="{ pay_div: payIndex == index }"
                        >
                            <img
                                    :src="item.name"
                                    alt=""
                                    v-if="item.value === '微信支付'"
                            /><span v-if="item.value === '微信支付'">{{ item.value }}</span>
                            <img
                                    :src="item.name"
                                    alt=""
                                    v-if="item.value !== '微信支付'"
                                    style="margin: 0 20px"
                            /><span v-if="item.value !== '微信支付'">{{ item.value }}</span>
                        </div>
                        <!--<div class="pay_div"><img :src="weipay" alt=""><span>微信支付</span></div>-->
                        <!--<div class="pay_div"><img :src="alipay" alt="" style="margin: 0 20px">支付宝支付</div>-->
                        <!--<div class="pay_div"><img :src="money" alt="" style="margin: 0 20px">线下支付</div>-->
                    </div>
                </div>
                <div
                        style="
            height: 180px;
            border-top: none;
            margin-top: 50px;
            padding-right: 50px;
            justify-content: flex-end;
          "
                        class="next_style common_liucheng;"
                >
                    <div
                            style="height: 40px; width: 120px"
                            class="button_style"
                            @click="submitOrder()"
                    >
                        提交订单
                    </div>
                    <div
                            style="
              height: 40px;
              width: 120px;
              background-color: #fc7c40;
              margin-left: 20px;
            "
                            class="button_style"
                            @click="
              nowIndex = 0;
              $parent.changeHeight();
               selection = [];
            "
                    >
                        返回年费代缴
                    </div>
                </div>
            </div>
        </div>
        <div v-if="nowIndex == 4">
            <div style="height: auto; border: 1px solid #dae2e5">
                <div
                        style="
            height: 70px;
            padding-left: 20px;
            line-height: 70px;
            font-size: 20px;
            background-color: rgb(243, 252, 252);
            color: black;
          "
                >
                    订单详情
                </div>
                <div style="padding-bottom: 30px" class="div_center">
          <span
                  style="
              display: inline-block;
              font-size: 18px;
              margin-top: 20px;
              color: black;
              font-weight: bold;
              margin-bottom: 40px;
            "
          >基本信息</span
          >
                    <div class="div_center" style="border-bottom: none">
                        <Row :gutter="16">
                            <Col span="12">
                                <Row>
                                    <Col span="24">
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >订单号</span
                      >：{{ detailItem.no }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >订单日期</span
                      >：{{ detailItem.createTime }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >订单总额</span
                      >：{{ detailItem.totalPrice }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >服务费</span
                      >：{{ detailItem.serviceCharge }}
                                        </div>
                                        <div
                                                v-if="detailItem.type == 0"
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >支付类型</span
                      >：支付宝
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                                v-if="detailItem.type == 1"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >支付类型</span
                      >：微信
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                                v-if="detailItem.type == 2"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >支付类型</span
                      >：线下
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span="12">
                                <Row>
                                    <Col span="24">
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap"
                                        >
                      <span
                              :title="detailItem.remarks"
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >买家留言</span
                      >：{{ detailItem.remarks || '无' }}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: auto; margin-bottom: 40px" class="div_center">
          <span
                  style="
              display: inline-block;
              font-size: 18px;
              margin-top: 20px;
              color: black;
              font-weight: bold;
              margin-bottom: 40px;
            "
          >商品清单</span
          >

                    <Table
                            border
                            :columns="detailColumn"
                            :data="detailData"
                            :disabled-hover="true"
                            style="margin-bottom: 100px"
                    >
                    </Table>
                </div>
                <div v-if="detailItem.billing == 1" style="height: auto;padding-bottom: 30px; border-bottom: none"
                     class="div_center">
          <span
                  style="
              display: inline-block;
              font-size: 18px;
              margin-top: 20px;
              color: black;
              font-weight: bold;
              margin-bottom: 40px;
            "
          >发票信息</span
          >
                    <div v-if="qyOrself">
                        <div>
                            <div
                                    class="liang_hang_sheng"
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                height: 40px;
                line-height:20px ;

              "
                            >
                                发票抬头：<span>{{ detailItem.invoiceTitle }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票邮寄地址：<span>{{ detailItem.invoiceAddress }}</span>
                            </div>
                        </div>
                        <div>
                            <div
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                 height: 40px;
                line-height:20px ;
              "
                                    class="liang_hang_sheng"
                            >
                                发票收件人姓名：<span>{{ detailItem.invoiceRecipientName }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票收件人联系方式：{{ detailItem.invoiceRecipientPhone }}<span></span>
                            </div>
                        </div>
                    </div>
                    <div v-if="!qyOrself">
                        <div>
                            <div
                                    class="liang_hang_sheng"
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                height: 40px;
                line-height:20px ;

              "
                            >
                                企业名称：<span>{{ detailItem.invoiceTitle }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                公司地址：<span>{{ detailItem.companyAddress }}</span>
                            </div>
                        </div>
                        <div>
                            <div
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                 height: 40px;
                line-height:20px ;
              "
                                    class="liang_hang_sheng"
                            >
                                企业税号：<span>{{ detailItem.taxpayerNumber }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票邮寄地址：{{ detailItem.invoiceAddress }}<span></span>
                            </div>
                        </div>
                        <div>
                            <div
                                    class="liang_hang_sheng"
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                height: 40px;
                line-height:20px ;

              "
                            >
                                企业开户行：<span>{{ detailItem.businessBank }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票收件人姓名：<span>{{ detailItem.invoiceRecipientName }}</span>
                            </div>
                        </div>
                        <div>
                            <div
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                 height: 40px;
                line-height:20px ;
              "
                                    class="liang_hang_sheng"
                            >
                                企业银行开行账号：<span>{{ detailItem.businessBankNo }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票收件人联系方式：{{ detailItem.invoiceRecipientPhone }}<span></span>
                            </div>
                        </div>
                        <div style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                 height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                            公司电话：<span>{{ detailItem.businessPhone }}</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div
                class="center"
                style="background-color: white; padding: 40px 0"
                v-if="nowIndex == 1"
        >
            <div
                    style="
          font-size: 24px;
          width: 100%;
          text-align: center;
          margin-bottom: 45px;
        "
            >
                您的订单已完成支付，请等待后台运营人员审核！
            </div>
            <div style="font-size: 14px; text-align: center; margin-bottom: 15px">
                支付金额：￥{{ priceSum }}
            </div>
            <div style="font-size: 14px; text-align: center; margin-bottom: 15px">
                订单号：{{ orderSn }}
            </div>
            <div style="font-size: 14px; text-align: center; margin-bottom: 30px">
                支付方式：{{ payTypeTxt }}
            </div>
            <div style="width: 410px; margin: 0 auto">
                <div
                        style="
            border-width: 1px;
            display: inline-block;
            border-color: rgb(255, 106, 0);
            border-style: solid;
            border-width: 1px;
            background-color: rgb(255, 255, 255);
            width: 178px;
            height: 38px;
            color: rgb(255, 106, 0);
            cursor: pointer;
            margin: 0 auto;
            text-align: center;
            line-height: 38px;
            margin-top: 30px;
          "
                        @click="seeDetail2()"
                >
                    查看订单
                </div>
                <div
                        @click="openZoosUrl"
                        style="
            border-width: 1px;
            display: inline-block;
            border-color: rgb(255, 106, 0);
            border-style: solid;
            border-width: 1px;
            background-color: rgb(255, 106, 0);
            width: 178px;
            height: 38px;
            color: white;
            cursor: pointer;
            margin: 0 auto;
            text-align: center;
            line-height: 38px;
            margin-top: 30px;
            margin-left: 15px;
          "
                >
                    联系客服
                </div>
            </div>
        </div>
        <div v-html="payForm"></div>

        <Modal
                v-model="payModal"
                title="微信支付"
                width="400"
                :footer-hide="footHide"
                @on-visible-change="watchPayModal"
        >
            <vue-qr :text="wechatUrl" :margin="0" :size="200"></vue-qr>
        </Modal>
        <!--    删除订单-->
        <Modal
                v-model="deleteOrderModal"
                title="删除订单信息"
                width="400"
                :footer-hide="footHide"
        >
            <div>
                <div style="padding: 20px">
                    <div
                            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
                    >
                        <div style="margin-bottom: 30px">
                            <span>您确定删除该订单信息？</span>
                        </div>
                        <div>
                            <div
                                    class="button_style"
                                    style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                                    @click="notDel()"
                            >
                                我再想想
                            </div>
                            <div
                                    class="button_style"
                                    style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                  background-color: #fda736;
                "
                                    @click="confirmDel()"
                            >
                                确定
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal
                v-model="lineModal"
                title="上传凭证"
                width="400"
                :footer-hide="footHide"
        >
            <Form :model="lineForm">
                <FormItem label="上传支付凭证：">
                    <Upload
                            action=""
                            :before-upload="(file) => handleUpload(file)"
                            :multiple="footHide"
                    >
                        <Button>选择文件</Button>
                        {{ hasChooseFile }}
                    </Upload>
                </FormItem>
                <FormItem label="备注：">
                    <Input v-model="lineForm.remarks" type="textarea"></Input>
                </FormItem>
            </Form>
            <div>
                <div
                        class="button_style"
                        style="margin-left: 26px; width: 80px; position: relative; top: 50%"
                        @click="notUpload()"
                >
                    我再想想
                </div>
                <div
                        class="button_style"
                        style="
            margin-left: 26px;
            width: 80px;
            position: relative;
            top: 50%;
            background-color: #fda736;
          "
                        @click="confirmUpload()"
                >
                    确定
                </div>
            </div>
        </Modal>
        <!--    取消订单-->
        <Modal
                v-model="removeOrderModal"
                title="取消年费代缴信息"
                width="400"
                :footer-hide="footHide"
        >
            <div>
                <div style="padding: 20px">
                    <div
                            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
                    >
                        <div style="margin-bottom: 30px">
                            <span>您确定取消该年费代缴信息？</span>
                        </div>
                        <div>
                            <div
                                    class="button_style"
                                    style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                                    @click="notRemove()"
                            >
                                我再想想
                            </div>
                            <div
                                    class="button_style"
                                    style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                  background-color: #fda736;
                "
                                    @click="confirmRemove()"
                            >
                                确定
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
    import weipay from "@/assets/image/science/wepay.png";
    import alipay from "@/assets/image/science/alipay.png";
    import money from "@/assets/image/science/money.png";
    import {
        getPaylist,
        cancelPay,
        delPay,
        goOrderCal,
        createOrder,
        nianfeiExport
    } from "../../../plugins/api/patentTrustee";
    import {getMonitorList} from "@/plugins/api/patentTrustee";
    import {uploadVoucher} from "@/plugins/api/patentTrustee";
    import {
        isCorrectPassword,
        isPhoneNumber,
        successToast,
        warnToast,
        errorToast,
    } from "@/plugins/tools/util";
    import {get} from "../../../plugins/http/http";
    import baseUrl, {patentUrl} from "../../../plugins/http/baseUrl";
    import {nianfeiUrl} from "../../../plugins/http/baseUrl";
    import {uploadImg} from "../../../plugins/api/sciencePolicy";
    import axios from "axios";
    import {getLocalToken} from "../../../plugins/tools/storage";

    export default {
        name: "AnnuaFreeCollection",
        data() {
            return {
                qyOrself: true,
                orderSn: "",
                wechatUrl: "",
                hasChooseFile: "未选择文件",
                lineForm: {
                    remarks: "",
                    paymentVoucher: "",
                    orderSn: "",
                },
                detailColumn: [
                    {
                        key: "orderSn",
                        title: "订单号",
                        align: "center",
                    },
                    {
                        key: "patentTi",
                        title: "专利名称",
                        align: "center",
                    },
                    {
                        key: "feeType",
                        title: "费用类型",
                        align: "center",
                    },
                    {
                        key: "shouldFee",
                        title: "应缴年费",
                        align: "center",
                    },
                    {
                        key: "feeDate",
                        title: "缴费截止日",
                        align: "center",
                    },
                    {
                        key: "feeStatus",
                        title: "缴费状态",
                        align: "center",
                    },
                ],
                id: "",
                payTypeTxt: "",
                priceNum: "",
                payForm: "",
                lineModal: false,
                payModal: false,
                daijiaofee: 0,
                servicefee: 0,
                patentNum: 0,
                paylist: [
                    {name: weipay, value: "微信支付"},
                    {name: alipay, value: "支付宝支付"},
                    {name: money, value: "线下支付"},
                ],
                payIndex: 0,
                priceSum: 0,
                animal: "无需发票",
                addtotal: 10,
                deleteOrderModal: false,
                deleteItem: {},
                detailItem: {},
                footHide: false,
                removeOrderModal: false,
                removeItem: {},
                total: 10, // 每条数量
                // 查询参数
                payParams: {
                    pageNum: 1,
                    pageSize: 10,
                    type: "0", // 订单状态: 0->全部；1->待支付；2->代缴中； 3->已完成
                    orderSn: "", //订单号
                    payMethod: "", // 支付类型
                },
                patentParams: {
                    keywords: "",
                    pageNum: 1,
                    pageSize: 10,
                },
                dataList: [],
                data7: [],
                faPiaoType: ["个人发票", "企业发票"],
                columnsPatent: [
                    {
                        key: "patentNo",
                        title: "专利申请号",
                        align: "center",
                    },
                    {
                        key: "patentTi",
                        title: "专利名称",
                        align: "center",
                    },
                    {
                        key: "feeType",
                        title: "费用名称",
                        align: "center",
                    },
                    {
                        key: "feeType",
                        title: "费用名称",
                        align: "center",
                    },
                    {
                        key: "shouldFee",
                        title: "应缴金额",
                        align: "center",
                    },
                    {
                        key: "feeDate",
                        title: "缴费日期",
                        align: "center",
                    },
                    {
                        key: "feeStatus",
                        title: "状态",
                        align: "center",
                    },
                    {
                        key: "Status",
                        title: "缴费状态",
                        align: "center",
                    },
                ],
                columns13: [
                    {
                        type: "selection",
                        width: 60,
                        align: "center",
                    },
                    {
                        title: "专利申请号",
                        key: "patentNo",
                        align: "center",
                        // render: (h, params) => {
                        //   return h('div', [
                        //     h('span', {
                        //     style: {
                        //       display: 'inline-block',
                        //       width: '100%',
                        //       overflow: 'hidden',
                        //       textOverflow: 'ellipsis',
                        //       whiteSpace: 'nowrap'
                        //     },
                        //     domProps: {
                        //       title: params.row.patentNo
                        //     }
                        //   }, params.row.patentNo)
                        // ])
                        // }
                    },
                    {
                        title: "专利名称",
                        key: "patentTi",
                        align: "center",
                        render: (h, params) => {
                            return h("div", [
                                h(
                                    "span",
                                    {
                                        style: {
                                            display: "inline-block",
                                            width: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        },
                                        domProps: {
                                            title: params.row.patentTi,
                                        },
                                    },
                                    params.row.patentTi
                                ),
                            ]);
                        },
                    },
                    {
                        title: "申请人",
                        key: "patentPa",
                        align: "center",
                        render: (h, params) => {
                            return h("div", [
                                h(
                                    "span",
                                    {
                                        style: {
                                            display: "inline-block",
                                            width: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        },
                                        domProps: {
                                            title: params.row.patentPa,
                                        },
                                    },
                                    params.row.patentPa
                                ),
                            ]);
                        },
                    },
                    {
                        title: "申请日",
                        key: "patentAd",
                        align: "center",
                    },
                    {
                        title: "缴费截止日",
                        key: "maxFeeDate",
                        align: "center",
                    },
                    {
                        title: "法律状态",
                        key: "patentLsnt",
                        align: "center",
                    },
                    {
                        title: "应缴费",
                        key: "sumShouldFee",
                        align: "center",
                        width: "80",
                    },
                ],
                selection: [],
                // 下拉框
                transfer: true,
                money: money,
                alipay: alipay,
                weipay: weipay,
                KaiPiao: true,
                nowIndex: 0,
                faPiaoIndex: 0,
                ordinaryInvoiceForm: {
                    qyName: "",
                    address: "",
                    name: "",
                    phone: "",
                },
                patentItem: [],
                vatInvoiceForm: {
                    qyName: "",
                    address: "",
                    qysh: "",
                    fpdz: "",
                    khh: "",
                    name: "",
                    khzh: "",
                    contact: "",
                    telephone: "",
                },
            };
        },
        methods: {
            async exportDemand() {
                let json = await axios({
                    method: "post",
                    url: `${patentUrl}/order/annuityExport`,
                    data: this.payParams,
                    responseType: "arraybuffer",
                    headers: {token: getLocalToken()},
                });
                let fileName = "报告.xls";
                if (json.status == 200) {

                    let blob = new Blob([json.data], {
                        type: "application/vnd.ms-excel};charset=utf-8",
                    });
                    let downloadElement = document.createElement("a");
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = fileName;
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);

                } else {
                    errorToast(json.message)
                }
            },
            openZoosUrl() {
                openZoosUrl('chatwin');
            },
            watchPayModal(status) {
                if (status == false) {
                    clearInterval(this.checkOrderTimer);
                }
            },
            seeDetail(item) {
                get(`${nianfeiUrl}/order/detail/${item.orderId}`).then((res) => {
                    let item = res.result;
                    this.detailItem.billing = item.billing;
                    // detailData.shopName = item.shopName
                    // detailData.goods = item.goods[0].patentTi;
                    // detailData.tradeType = item.goods[0].modeName;
                    this.detailItem.no = item.orderSn;
                    this.detailItem.price = "¥" + item.paymentAmount;
                    let dateee2 = new Date(item.createTime).toJSON();
                    item.createTime = new Date(+new Date(dateee2) + 8 * 3600 * 1000)
                        .toISOString()
                        .replace(/T/g, " ")
                        .replace(/\.[\d]{3}Z/, "")
                        .split(" ")[0];
                    this.detailItem.createTime = item.createTime;
                    this.detailItem.type = item.payMethod;
                    this.detailItem.remarks = item.remarks;
                    this.detailItem.serviceCharge = "¥" + item.serviceAmount;
                    this.detailItem.totalPrice = "¥" + item.totalAmount;
                    this.detailItem.invoiceRecipientName = item.invoiceRecipientName
                        ? item.invoiceRecipientName
                        : "无";
                    this.detailItem.invoiceTitle = item.invoiceTitle
                        ? item.invoiceTitle
                        : "无";
                    this.detailItem.invoiceRecipientPhone = item.invoiceRecipientPhone
                        ? item.invoiceRecipientPhone
                        : "无";
                    this.detailItem.invoiceAddress = item.invoiceAddress
                        ? item.invoiceAddress
                        : "无";
                    this.detailItem.taxpayerNumber = item.taxpayerNumber
                        ? item.taxpayerNumber
                        : "无";
                    this.detailItem.businessPhone = item.businessPhone
                        ? item.businessPhone
                        : "无";
                    this.detailItem.businessBankNo = item.businessBankNo
                        ? item.businessBankNo
                        : "无";
                    this.detailItem.businessBank = item.businessBank
                        ? item.businessBank
                        : "无";
                    this.detailItem.companyAddress = item.companyAddress
                        ? item.companyAddress
                        : "无";

                    if (item.companyAddress || item.companyAddress == "") {
                        this.qyOrself = false;
                    } else {
                        this.qyOrself = true;
                    }
                    this.detailData = item.detail;
                    this.$router.push({
                        path: "/enterprise/patent-trusteeship",
                        query: {
                            id: 1,
                        },
                    });
                    this.nowIndex = 4;
                    this.$parent.changeHeight();
                });
            },
            seeDetail2() {
                get(`${nianfeiUrl}/order/detail/${this.id}`).then((res) => {
                    let item = res.result;
                    // detailData.shopName = item.shopName
                    // detailData.goods = item.goods[0].patentTi;
                    // detailData.tradeType = item.goods[0].modeName;
                    this.detailItem.billing = item.billing;
                    this.detailItem.no = item.orderSn;
                    this.detailItem.price = "¥" + item.paymentAmount;
                    let dateee2 = new Date(item.createTime).toJSON();
                    item.createTime = new Date(+new Date(dateee2) + 8 * 3600 * 1000)
                        .toISOString()
                        .replace(/T/g, " ")
                        .replace(/\.[\d]{3}Z/, "")
                        .split(" ")[0];
                    this.detailItem.createTime = item.createTime;
                    this.detailItem.type = item.payMethod;
                    this.detailItem.remarks = item.remarks;
                    this.detailItem.serviceCharge = "¥" + item.serviceAmount;
                    this.detailItem.totalPrice = "¥" + item.totalAmount;
                    this.detailItem.invoiceRecipientName = item.invoiceRecipientName
                        ? item.invoiceRecipientName
                        : "无";
                    this.detailItem.invoiceTitle = item.invoiceTitle
                        ? item.invoiceTitle
                        : "无";
                    this.detailItem.invoiceRecipientPhone = item.invoiceRecipientPhone
                        ? item.invoiceRecipientPhone
                        : "无";
                    this.detailItem.invoiceAddress = item.invoiceAddress
                        ? item.invoiceAddress
                        : "无";
                    this.detailItem.taxpayerNumber = item.taxpayerNumber
                        ? item.taxpayerNumber
                        : "无";
                    this.detailItem.businessPhone = item.businessPhone
                        ? item.businessPhone
                        : "无";
                    this.detailItem.businessBankNo = item.businessBankNo
                        ? item.businessBankNo
                        : "无";
                    this.detailItem.businessBank = item.businessBank
                        ? item.businessBank
                        : "无";
                    this.detailItem.companyAddress = item.companyAddress
                        ? item.companyAddress
                        : "无";
                    if (item.companyAddress || item.companyAddress == "") {
                        this.qyOrself = false;
                    } else {
                        this.qyOrself = true;
                    }
                    this.detailData = item.detail;
                    this.$router.push({
                        path: "/enterprise/patent-trusteeship",
                        query: {
                            id: 1,
                        },
                    });
                    this.nowIndex = 4;
                    this.$parent.changeHeight();
                });
            },
            notUpload() {
                this.lineModal = false;
            },
            async confirmUpload() {
                let json = await uploadVoucher(this.lineForm);
                if (json.code == 0) {
                    this.lineForm.paymentVoucher = "";
                    this.lineForm.orderSn = "";
                    this.lineForm.remarks = ""
                    successToast("上传成功，等待客服确认");
                    this.lineModal = false;
                    this.getPay();
                } else {
                    errorToast(json.message);
                }
            },
            goPay(row) {
                this.id = row.id;
                let orderId = row.id;
                this.orderSn = row.orderSn
                this.lineForm.orderSn = row.orderSn;
                this.priceSum = row.actualAmount || row.totalAmount;
                get(`${nianfeiUrl}/pay/${orderId}`, {
                    notifyUrl: "",
                    returnUrl:
                        location.href +
                        `&payStatus=1&orderId=${orderId}&payType=${row.payMethod}`,
                }).then((res) => {
                    if (res.code === 0) {
                        //this.shop = res.result.shop;
                        if (row.payMethod == "0") {
                            this.payTypeTxt = "支付宝支付";
                            this.payForm = res.result.form;
                            setTimeout(() => {
                                document.forms[0].submit();
                            });
                        }
                        if (row.payMethod == "2") {
                            this.payTypeTxt = "线下支付";
                            this.lineModal = true;
                            scrollTo(0, 0);
                            this.$parent.changeHeight();
                        }
                        if (row.payMethod == "1") {
                            this.payTypeTxt = "微信支付";
                            this.payModal = true;
                            this.wechatUrl = res.result.params.code_url;
                            this.loopCheckOrder(orderId);
                        }
                    } else {
                        errorToast(res.message);
                    }
                });
            },
            goPay1(row) {
                this.id = row.id;
                let orderId = row.id;
                this.orderSn = row.orderSn
                this.lineForm.orderSn = row.orderSn;
                this.priceSum = row.actualAmount || row.totalAmount;
                get(`${nianfeiUrl}/pay/${orderId}`, {
                    notifyUrl: "",
                    returnUrl:
                        location.href +
                        `&payStatus=1&orderId=${orderId}&payType=${row.payMethod}`,
                }).then((res) => {
                    if (res.code === 0) {
                        //this.shop = res.result.shop;
                        if (row.payMethod == "0") {
                            this.payTypeTxt = "支付宝支付";
                            this.payForm = res.result.form;
                            setTimeout(() => {
                                document.forms[0].submit();
                            });
                        }
                        if (row.payMethod == "2") {
                            this.payTypeTxt = "线下支付";
                            this.nowIndex = 5;
                            scrollTo(0, 0);
                            this.$parent.changeHeight();
                        }
                        if (row.payMethod == "1") {
                            this.payTypeTxt = "微信支付";
                            this.payModal = true;
                            this.wechatUrl = res.result.params.code_url;
                            this.loopCheckOrder(orderId);
                        }
                    } else {
                        errorToast(res.message);
                    }
                });
            },
            checkPayStatus(id) {
                get(`${nianfeiUrl}/order/detail/${id}`).then((res) => {
                    if (res.code === 0) {

                        if (res.result.status == "1") {
                            clearInterval(this.checkOrderTimer);
                            this.nowIndex = 1;
                            scrollTo(0, 0);
                            this.payModal = false;
                            this.orderId = res.result.id;
                            this.orderSn = res.result.orderSn
                            this.$parent.changeHeight();
                        }
                    }
                });
            },
            loopCheckOrder(id) {
                this.checkOrderTimer = setInterval(() => {
                    this.checkPayStatus(id);
                }, 5000);
            },
            async goJiaoFei() {
                if (this.selection.length > 0) {
                    let data = [];
                    this.selection.forEach((item, index) => {
                        let perData = {};
                        perData["id"] = item.id;
                        perData["patentNo"] = item.patentNo;
                        data.push(perData);
                    });

                    let json = await goOrderCal(data);
                    if (json.code == 200) {


                        this.patentItem = json.data.patentList;
                        this.patentItem.forEach((item, index) => {
                            item["Status"] = "/";
                        });
                        this.patentNum = json.data.nums;
                        this.servicefee = json.data.sumServiceFee;
                        this.priceSum = json.data.sumPayFee;
                        this.daijiaofee = json.data.payFee;
                        this.nowIndex = 2;
                    } else {
                        errorToast(json.message);
                    }
                } else {
                    warnToast("请至少选择一条数据");
                }
                this.$parent.changeHeight();
            },


            async handleUpload(file) {
                let that = this;
                const FileExt = file.name.replace(/.+\./, ""); //取得文件的后缀名
                if (FileExt == "png" || FileExt == "jpg") {
                    const reader = new FileReader();
                    //将文件读取为 DataURL 以data:开头的字符串
                    let code = "";
                    let _that = this;
                    reader.readAsDataURL(file);
                    reader.onload = (e) => {
                        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
                        code = e.target.result;

                        let data = {
                            imageBase64: code,
                            dataType: 0,
                        };
                        uploadImg(data).then((res) => {
                            if (res.code == "200") {
                                successToast("上传成功");
                                that.lineForm.paymentVoucher = res.data;
                                this.hasChooseFile = "已选择";
                            } else {
                                errorToast(res.message);
                            }
                        });
                    };
                } else {
                    warnToast("只支持jpg和png");
                    this.file = "";
                }

                // if(json.code == 200){

                // }
            },
            // 批量
            selectMu(selection) {
                this.selection = selection;
            },
            // 查询
            async search() {
                this.payParams.pageNum = 1;
                this.getPay();
            },
            // 新增列表
            async getPatentList() {
                let json = await getMonitorList(this.patentParams);
                if (json.code == 0) {
                    this.data7 = json.result.list;
                    this.addtotal = json.result.total;

                    this.data7.forEach((item, index) => {
                        let dateee = new Date(item.maxFeeDate).toJSON();
                        item.maxFeeDate = new Date(+new Date(dateee) + 8 * 3600 * 1000)
                            .toISOString()
                            .replace(/T/g, " ")
                            .replace(/\.[\d]{3}Z/, "")
                            .split(" ")[0];
                        let dateee2 = new Date(item.patentAd).toJSON();
                        item.patentAd = new Date(+new Date(dateee2) + 8 * 3600 * 1000)
                            .toISOString()
                            .replace(/T/g, " ")
                            .replace(/\.[\d]{3}Z/, "")
                            .split(" ")[0];
                    });
                    this.$forceUpdate();
                } else {
                    errorToast(json.message);
                }
                this.$parent.changeHeight();
            },
            async searchAdd() {
                this.patentParams.pageNum = 1;
                this.getPatentList();
            },

            //新增翻页
            async changePageAdd(index) {
                this.patentParams.pageNum = index;
                this.getPatentList();
            },
            // 翻页
            async pageChange(index) {
                this.payParams.pageNum = index;
                this.getPay();
            },
            // 得到列表
            async getPay() {
                let json = await getPaylist(this.payParams);
                if (json.code == 0) {
                    this.total = json.data.total;
                    this.dataList = json.data.list;
                    this.dataList.forEach((item, index) => {
                        let dateee2 = new Date(item.createTime).toJSON();
                        item.createTime = new Date(+new Date(dateee2) + 8 * 3600 * 1000)
                            .toISOString()
                            .replace(/T/g, " ")
                            .replace(/\.[\d]{3}Z/, "")
                            .split(" ")[0];
                    });
                } else {
                    errorToast(json.message);
                }
                this.$parent.changeHeight();
            },
            // 翻页
            async changePage(index) {
                this.payParams.pageNum = index;
                this.getPay();
            },

            delOrder(item) {
                this.deleteItem = item;
                this.deleteOrderModal = true;
            },
            // 取消删除
            notDel() {
                this.deleteOrderModal = false;
            },
            // 确定删除
            async confirmDel() {
                let data = {
                    orderSn: this.deleteItem.orderSn,
                };
                let json = await delPay(data);
                if (json.code == 200) {
                    this.getPatentList();
                    this.deleteOrderModal = false;
                } else {
                    errorToast(json.message);
                }
            },

            // 取消
            removeOrder(item) {
                this.removeOrderModal = true;
                this.removeItem = item;
            },
            // 取消
            notRemove() {
                this.removeOrderModal = false;
            },
            // 确定取消
            async confirmRemove() {
                let data = {
                    orderSn: this.removeItem.orderSn,
                };
                let json = await cancelPay(data);
                if (json.code == 0) {
                    this.getPay();
                } else {
                    warnToast(json.message)
                }
                this.removeOrderModal = false;
            },

            faPiaochoose(index) {
                this.faPiaoIndex = index;
            },
            /**
             * 对象数组去重,type表示对象里面的一个属性
             */
            uniqueFun(arr, type) {
                const res = new Map();
                return arr.filter((a) => !res.has(a[type]) && res.set(a[type], 1));
            },
            async submitOrder() {
                let data = {
                    sumPayFee: this.priceSum,
                    billing: 0,
                };
                switch (this.paylist[this.payIndex].value) {
                    case "支付宝支付":
                        data.payMethod = 0;
                        break;
                    case "微信支付":
                        data.payMethod = 1;
                        break;
                    case "线下支付":
                        data.payMethod = 2;
                        break;
                }
                data.patentList = [];

                for (let item of this.patentItem) {
                    let perData = {};
                    perData.patentNo = item.patentNo;
                    perData.id = item.id;
                    data.patentList.push(perData);
                }
                let result = [];
                result = this.uniqueFun(data.patentList, "id");
                data.patentList = result
                if (this.animal == "无需发票") {
                } else {
                    data.billing = 1;
                    if (this.faPiaoIndex == 0) {
                        data.invoiceTitle = this.ordinaryInvoiceForm.qyName;
                        data.invoiceAddress = this.ordinaryInvoiceForm.address;
                        data.invoiceRecipientName = this.ordinaryInvoiceForm.name;
                        data.invoiceRecipientPhone = this.ordinaryInvoiceForm.phone;
                    } else {
                        data.invoiceTitle = this.vatInvoiceForm.qyName;
                        data.companyAddress = this.vatInvoiceForm.address;
                        data.invoiceRecipientName = this.vatInvoiceForm.name;
                        data.invoiceRecipientPhone = this.vatInvoiceForm.contact;
                        data.businessPhone = this.vatInvoiceForm.telephone;
                        data.businessBank = this.vatInvoiceForm.khh;
                        data.businessBankNo = this.vatInvoiceForm.khzh;
                        data.taxpayerNumber = this.vatInvoiceForm.qysh;
                        data.invoiceAddress = this.vatInvoiceForm.fpdz
                    }
                }
                let json = await createOrder(data);
                if (json.code == 0) {
                    successToast(json.message);
                    this.nowIndex = 0;
                    this.goPay1(json.data);
                } else {
                    errorToast(json.message);
                }

                //
                // 生成代缴订单（提交订单）：order/creat
                // 入参：
                // 		patentList[patentNo、id] 专利信息、sumPayFee 费用总计、billing 是否需要发票、invoiceType 发票类型、
                // 		invoiceAddress 发票邮寄地址、invoiceTitle 发票抬头、taxpayerNumber 纳税人识别号、
                // 		invoiceRecipientName 发票收件人姓名、invoiceRecipientPhone 发票收件人电话、
                // 		companyAddress 企业地址、businessBank 企业开户行、
                // 		businessBankNo 企业开户账号、businessPhone 公司电话、
                // 		payMethod 支付方式、transactionType 交易类型
            },
        },
        mounted() {
            if (this.$route.query.payStatus) {
                this.id = this.$route.query.orderId;
                let id = this.$route.query.orderId;
                let _that = this;
                get(`${nianfeiUrl}/order/detail/${id}`).then((res) => {
                    if (res.code == 0) {
                        this.priceSum = res.result.totalAmount;
                        this.orderId = res.result.orderSn;
                        this.orderSn = res.result.orderSn;
                        switch (res.result.payMethod) {
                            case 0:
                                _that.payTypeTxt = "支付宝支付";
                                break;
                            case 1:
                                _that.payTypeTxt = "微信支付";
                                break;
                            case 2:
                                _that.payTypeTxt = "线下支付";
                                break;
                        }
                    }
                });
                this.nowIndex = this.$route.query.payStatus;
                scrollTo(0, 0);
                this.$parent.changeHeight();
                this.$route.query.payStatus = "";
            } else {
                this.nowIndex = 0;
                this.getPay();
            }
        },
    };
</script>

<style scoped lang="scss">

    /deep/ #renderSelect .ivu-modal .ivu-select-dropdown {
        position: fixed !important;
    }

    #button_style div:nth-child(1) {
        margin-left: 50px;
    }

    #button_style div:nth-child(2) {
        margin-left: 25px;
    }

    .div_button {
        cursor: pointer;
        border-width: 1px;
        border-color: rgb(220, 220, 220);
        border-style: solid;
        border-radius: 4px;
        display: inline-block;
        width: 114px;
        height: 40px;
        line-height: 40px;
        text-align: center;
    }

    .ivu-col {
        //padding-right: 100px;
    }

    .div_button_select {
        cursor: pointer;
        border-width: 1px;
        border-color: #1767E0;
        border-style: solid;
        border-radius: 4px;
        display: inline-block;
        width: 114px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #1767E0;
    }

    /deep/ .ivu-select-selection {
        border-radius: 0px;
    }

    /deep/ .ivu-radio-inner:after {
        background-color: #1767E0;
    }

    .order_span {
        padding: 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 1500px) {
        .order_span {
            font-size: 13px !important;
        }
    }

    /deep/ .ivu-table-tbody tr:nth-child(2n) td {
        background-color: #f3fcfc !important;
        color: black !important;
    }

    /deep/ th,
    /deep/ td {
        border-right: none !important;
    }

    .div_center {
        width: 95%;
        margin-left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted rgb(218, 226, 229);
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nopay_div {
        display: flex;
        height: 60px;
        width: 180px;
        border: 1px solid #dcdcdc;
        margin-left: 20px;
        font-size: 20px;
        align-items: center;
        cursor: pointer;
    }

    .pay_div {
        display: flex;
        height: 60px;
        width: 180px;
        border: 1px solid #37c5cf;
        margin-left: 20px;
        font-size: 20px;
        align-items: center;
        cursor: pointer;
    }

    .div_center_two {
        width: 95%;
        margin-left: 50%;
        transform: translateX(-50%);
        /*border-bottom: 2px dotted rgb(218, 226, 229)*/
    }

    .right_real_content {
        margin: 50px 20px;
        overflow: hidden;
    }

    .wxQrcodePay {
        img {
            width: 200px !important;
            height: 200px !important;
        }
    }

    .right_header {
        padding: 20px;
        margin-bottom: 40px !important;
        border-bottom: 1px solid #e8eaec;
        overflow: hidden;

        span {
            color: #597697;
            font-size: 14px;
        }

        .query-form {
            > div {
                margin-right: 10px;
            }
        }
    }

    .button_style {
        cursor: pointer;
        font-size: 14px;
        margin-left: 10px;
        display: inline-block;
        background: rgb(14, 97, 223);
        z-index: 51;
        text-align: center;
        color: white;
        cursor: pointer;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 27px;
        line-height: 32px;
        height: 32px;
        border-radius: 4px;
    }

    .input_search,
    .input_search:focus {
        border-width: 1px;
        border-color: rgb(55, 197, 207);
        border-style: solid;
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
        width: 300px;
        height: 40px;
        outline: none;
    }

    /deep/ #djnf2 .ivu-col {
        padding: 0 2px;
        border-right: none !important;
        // border-top: 1px solid rgba(205, 240, 243, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        overflow: hidden;
    }

    .liang_hang_sheng {
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>
